import { type EngagementActivityFeedComponent } from "../constants";

import { EngagementActivityFeedItem } from "./EngagementActivityFeedItem";

export const EngagementActivityFeedItemBudgetSpent: EngagementActivityFeedComponent =
  ({ id, before, after, timestamp }) => {
    // TODO: Figure out why we aren't getting the before and after values
    return (
      <EngagementActivityFeedItem timestamp={timestamp} key={id}>
        The budget consumed was updated
        {/* from {before}% → {after}% */}
      </EngagementActivityFeedItem>
    );
  };
