import clsx from "clsx";
import { useState } from "react";

import { Table, Tabs } from "@m/ui";
import { formatFullDate, toDollars, toPercent } from "@m/utils";

import { Page } from "@mc/components/Page";
import { PageHeading } from "@mc/components/PageHeading";
import { PageTitle } from "@mc/components/PageTitle";

export const RioReportingPage = () => {
  const [selectedTimeRange, setSelectedTimeRange] = useState(
    TIME_RANGE_TABS[2]
  );
  const [selectedDetailsTab, setSelectedDetailsTab] = useState(
    RIO_DETAILS_TABS[0]
  );

  const startDate = "2022-12-15"; // todo(djbowers): wire-up
  const allTimeGrossSavings = 47265.12; // todo(djbowers): wire-up
  const allTimeEffectiveSavingsRate = 0.4603; // todo(djbowers): wire-up

  const grossSavings = 47265.12; // todo(djbowers): wire-up
  const onDemandEquivalent = 92363.77; // todo(djbowers): wire-up
  const effectiveSavingsRate = 0.4603; // todo(djbowers): wire-up

  const additionalTableHeaders = getAdditionalTableHeaders(selectedDetailsTab);

  return (
    <Page
      title={
        <PageTitle
          title="Reserved Instance Operations"
          padding="small"
          border="none"
        />
      }
      width="full"
      className="flex flex-col gap-y-5"
    >
      <div>
        <PageHeading
          heading="All-Time"
          description={<>Since {formatFullDate(startDate, "LLLL yyyy")}</>}
        />
        <div className="flex gap-x-3">
          <Tile
            title="Gross Savings"
            content={toDollars(allTimeGrossSavings)}
            fitToContent
          />
          <Tile
            title="Effective Savings Rate"
            content={toPercent(allTimeEffectiveSavingsRate)}
            fitToContent
          />
        </div>
      </div>

      <div className="flex flex-col gap-y-5">
        <PageHeading
          heading="Costs"
          actions={
            <Tabs
              onTabChange={setSelectedTimeRange}
              selectedTab={selectedTimeRange}
              tabs={TIME_RANGE_TABS}
            />
          }
        />
        <div className="flex gap-x-3">
          <Tile title="Gross Savings" content={toDollars(grossSavings)} />
          <Tile
            title="On-Demand Equivalent"
            content={toDollars(onDemandEquivalent)}
          />
          <Tile
            title="Effective Savings Rate"
            content={toPercent(effectiveSavingsRate)}
          />
        </div>
      </div>

      <div className="flex flex-col gap-y-4">
        <Tabs
          onTabChange={setSelectedDetailsTab}
          selectedTab={selectedDetailsTab}
          tabs={RIO_DETAILS_TABS}
        />
        <Table
          ariaLabel="Rio Details Table"
          headers={[
            {
              label: "Effective Savings Rate",
              accessor: "effectiveSavingsRate",
              width: "200px",
            },
            ...additionalTableHeaders,
            { label: "Savings", accessor: "savings", width: "150px" },
            {
              label: "On-Demand Cost",
              accessor: "onDemandCost",
              width: "300px",
              align: "right",
            },
          ]}
          rows={[]}
        />
      </div>
    </Page>
  );
};

const Tile = ({
  content,
  fitToContent = false,
  title,
}: {
  content: string;
  fitToContent?: boolean;
  title: string;
}) => (
  <div
    className={clsx("flex flex-col gap-y-0.5 rounded bg-gray-50 px-3 py-2", {
      "w-fit": fitToContent,
      "w-full": !fitToContent,
    })}
  >
    <div className="text-center text-xs font-semibold">{title}</div>
    <div className="text-center text-2xl font-semibold">{content}</div>
  </div>
);

const TIME_RANGE_TABS = ["12 Months", "Current Year", "Current Month"];

const RIO_DETAILS_TABS = [
  "Accounts",
  "AWS Service",
  "Instance Types",
  "Regions",
  "Operating Systems",
];

const ACCOUNTS_TABLE_HEADERS = [
  { label: "Account ID", accessor: "accountId" },
  { label: "Account Name", accessor: "accountName" },
];

const AWS_SERVICE_TABLE_HEADERS = [{ label: "Service", accessor: "service" }];

const INSTANCE_TYPE_TABLE_HEADERS = [
  { label: "Instance Type", accessor: "instanceType" },
  { label: "OS", accessor: "os" },
];

const REGION_TABLE_HEADERS = [{ label: "Region", accessor: "region" }];

const OPERATING_SYSTEM_TABLE_HEADERS = [{ label: "OS", accessor: "os" }];

const getAdditionalTableHeaders = (selectedRioTab: string) => {
  switch (selectedRioTab) {
    case "Accounts":
      return ACCOUNTS_TABLE_HEADERS;
    case "AWS Service":
      return AWS_SERVICE_TABLE_HEADERS;
    case "Instance Types":
      return INSTANCE_TYPE_TABLE_HEADERS;
    case "Regions":
      return REGION_TABLE_HEADERS;
    case "Operating Systems":
      return OPERATING_SYSTEM_TABLE_HEADERS;
  }
};
