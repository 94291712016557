import { generatePath } from "react-router-dom";

import { ActivityStreamOperation } from "@m/api/public/types";
import { Link } from "@m/ui";

import { PATHS } from "@mc/constants";

import { type EngagementActivityFeedComponent } from "../constants";

import { EngagementActivityFeedItem } from "./EngagementActivityFeedItem";

export const EngagementActivityFeedItemActionItem: EngagementActivityFeedComponent =
  ({ timestamp, model: actionItem, operation, changes }) => {
    const { id, title } = actionItem;

    // Filter out unchanged values
    const filteredChanges = Object.fromEntries(
      Object.entries(changes).filter(([, values]) => values[0] !== values[1])
    );

    if (
      !actionItem ||
      (operation === ActivityStreamOperation.Update &&
        !Object.keys(filteredChanges).length)
    )
      return null;

    return (
      <EngagementActivityFeedItem timestamp={timestamp}>
        <ChangeDescription
          id={id}
          title={title}
          operation={operation}
          changes={filteredChanges}
        />
      </EngagementActivityFeedItem>
    );
  };

const ChangeDescription = ({
  id,
  title,
  operation,
  changes,
}: {
  id: string;
  title: string;
  operation: ActivityStreamOperation;
  changes: { [k: string]: unknown };
}) => {
  if (!changes) return null;

  if (operation === ActivityStreamOperation.Create) {
    return (
      <>
        The action item{" "}
        <Link
          fill="none"
          to={`../${generatePath(PATHS._ENGAGEMENT_CHECKLIST_DETAILS, {
            actionItemId: id,
          })}`}
        >
          {title}
        </Link>{" "}
        was created
      </>
    );
  }

  const newOpenStatus = changes["open"][1]; // This returns a boolean representing whether the action item is open or not

  return (
    <div>
      The action item{" "}
      <Link
        fill="none"
        to={`../${generatePath(PATHS._ENGAGEMENT_CHECKLIST_DETAILS, {
          actionItemId: id,
        })}`}
      >
        {title}
      </Link>{" "}
      was {newOpenStatus ? "reopened" : "completed"}
    </div>
  );
};
