import { generatePath } from "react-router-dom";

import {
  ActivityStreamOperation,
  EngagementDecisionOwner,
} from "@m/api/public/types";
import { useAuth } from "@m/login";
import { Link, Tooltip } from "@m/ui";
import { fromSnakeCaseToProperCase, toProperCase } from "@m/utils";
import { formatFullDate } from "@m/utils/datetime";

import { PATHS } from "@mc/constants";

import { type EngagementActivityFeedComponent } from "../constants";

import { EngagementActivityFeedItem } from "./EngagementActivityFeedItem";

export const EngagementActivityFeedItemDecision: EngagementActivityFeedComponent =
  ({ timestamp, model: decision, operation, changes }) => {
    const { id, title } = decision;

    // Filter out unchanged values
    const filteredChanges = Object.fromEntries(
      Object.entries(changes).filter(([, values]) => values[0] !== values[1])
    );

    if (
      !decision ||
      (operation === ActivityStreamOperation.Update &&
        !Object.keys(filteredChanges).length)
    )
      return null;

    return (
      <EngagementActivityFeedItem timestamp={timestamp}>
        <ChangeDescription
          id={id}
          title={title}
          operation={operation}
          changes={filteredChanges}
        />
      </EngagementActivityFeedItem>
    );
  };

const ChangeDescription = ({
  id,
  title,
  operation,
  changes,
}: {
  id: string;
  title: string;
  operation: ActivityStreamOperation;
  changes: { [k: string]: unknown };
}) => {
  const { user } = useAuth();
  if (!changes) return null;

  const changedValuesLength = Object.keys(changes).length;

  if (operation === ActivityStreamOperation.Create) {
    return (
      <>
        The decision{" "}
        <Link
          fill="none"
          to={`../${generatePath(PATHS._ENGAGEMENT_DECISION_DETAILS, {
            decisionId: id,
          })}`}
        >
          {title}
        </Link>{" "}
        was created
      </>
    );
  }

  const getChangeDescription = (
    key: string,
    value: unknown,
    isLast: boolean
  ) => {
    const formatOwner = (val) =>
      val === EngagementDecisionOwner.Mission
        ? "Mission Cloud"
        : user.company.name;

    const formatTooltipContent = (key, value) => {
      switch (key) {
        case "due_date":
          return `${formatFullDate(value[0]) || "None"} → ${formatFullDate(
            value[1]
          )}`;
        case "owner":
          return `${formatOwner(value[0])} → ${formatOwner(value[1])}`;
        default:
          return `${toProperCase(value[0])} → ${toProperCase(value[1])}`;
      }
    };

    const label = fromSnakeCaseToProperCase(key);

    return (
      <span key={key}>
        {changedValuesLength > 1 && isLast && " and "}
        <Tooltip content={formatTooltipContent(key, value)} asChild>
          <span className="cursor-help border-b-[1px] border-dashed">
            {label}
          </span>
        </Tooltip>
        {!isLast && ", "}
      </span>
    );
  };

  return (
    <div>
      {Object.entries(changes).map(([key, value], index) => {
        const isLast = index === changedValuesLength - 1;
        return getChangeDescription(key, value, isLast);
      })}
      {changedValuesLength > 1 ? " were" : " was"} updated for{" "}
      <Link
        fill="none"
        to={`../${generatePath(PATHS._ENGAGEMENT_DECISION_DETAILS, {
          decisionId: id,
        })}`}
      >
        {title}
      </Link>
    </div>
  );
};
