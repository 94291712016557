import { useAuth } from "@m/login";

import { AccessRequired } from "@mc/components/AccessRequired";
import { Page } from "@mc/components/Page";
import { PageTitle } from "@mc/components/PageTitle";
import { FEATURE_FLAGS, SUPPORT_CASES_FEATURE_FLAGS } from "@mc/constants";

import {
  CaseMetricsWidget,
  CloudScoreWidget,
  DashboardActivityFeed,
  Greeting,
  MissingIamRoleBanner,
  MyTeamWidget,
  ProjectSummaryWidget,
} from "../components";

export const DashboardPage = () => {
  const { user } = useAuth();

  return (
    <Page data-testid="dashboard" width="large">
      <MissingIamRoleBanner />
      <PageTitle
        border="none"
        className="mb-4"
        padding="none"
        title={<Greeting firstName={user.firstName} />}
      />
      <div className="flex flex-col gap-3">
        <div className="grid grid-cols-1 space-y-3 lg:grid-cols-3 lg:gap-3 lg:space-y-0">
          <div className="flex flex-col gap-3">
            <AccessRequired feature={FEATURE_FLAGS.CLOUD_SCORE_ALLOW_ACCESS}>
              <CloudScoreWidget />
            </AccessRequired>
            <AccessRequired
              feature={SUPPORT_CASES_FEATURE_FLAGS}
              matchFeatures="any"
            >
              <CaseMetricsWidget />
            </AccessRequired>
            <AccessRequired feature={FEATURE_FLAGS.ENGAGEMENTS_ALLOW_ACCESS}>
              <ProjectSummaryWidget />
            </AccessRequired>
            <MyTeamWidget />
          </div>
          <div className="col-span-2">
            <DashboardActivityFeed />
          </div>
        </div>
      </div>
    </Page>
  );
};
