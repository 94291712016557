import { useMemo } from "react";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";
import { dt } from "@m/utils";

import { ACTIVITY_FEED_MESSAGES } from "@mc/features/ActivityFeed/constants";
import { ENGAGEMENT_ACTIVITY_FEED_MAP } from "@mc/features/EngagementsActivityFeed/constants";
import { useQuery } from "@mc/utils/graphql";

export const GET_ENGAGEMENT_ACTIVITY_FEED_CHANGES_COUNT = gql(`
    query EngagementActivityFeedChangesCount($activityStreamFilter: ActivityStreamFilter, $first: Int, $after: String) {
        activityStream(where: $activityStreamFilter, first: $first, after: $after) {
            totalCount
        }
    }
`);

export const useEngagementActivityFeedChangesCount = () => {
  const onError = () =>
    toast.error(ACTIVITY_FEED_MESSAGES.GET_ACTIVITY_STREAM_FAILURE);

  const cutoff = useMemo(() => dt.utc().minus({ days: 30 }).toISO(), []);

  const streamTypes = [
    ENGAGEMENT_ACTIVITY_FEED_MAP.EngagementStartActivityStream,
    ENGAGEMENT_ACTIVITY_FEED_MAP.EngagementBudgetSpentActivityStream,
    ENGAGEMENT_ACTIVITY_FEED_MAP.EngagementBudgetStatusChangeActivityStream,
    ENGAGEMENT_ACTIVITY_FEED_MAP.EngagementDecisionActivityStream,
    ENGAGEMENT_ACTIVITY_FEED_MAP.EngagementActionItemCreatedActivityStream,
    ENGAGEMENT_ACTIVITY_FEED_MAP.EngagementActionItemCompletedActivityStream,
    ENGAGEMENT_ACTIVITY_FEED_MAP.EngagementActionItemNoteAddedActivityStream,
  ].map((stream) => stream.type);

  const { data, loading, error } = useQuery(
    GET_ENGAGEMENT_ACTIVITY_FEED_CHANGES_COUNT,
    {
      variables: {
        activityStreamFilter: {
          timestampGt: cutoff,
          typeIn: streamTypes,
          // TODO(jamesmoody): We'll need some type of filter to get the count of changes for a specific engagement
        },
      },
      onError,
    }
  );

  const activityCount = data?.activityStream?.totalCount || 0;

  return {
    data: { activityCount },
    loading,
    error: error !== undefined,
  };
};
