import clsx from "clsx";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import { Engagement, EngagementType } from "@m/api/public/types";
import { Badge, Link, Suspensed, Tooltip } from "@m/ui";

import { useAccessRequired } from "@mc/components/AccessRequired";
import { PageTitle } from "@mc/components/PageTitle";
import { PATHS, ROLES } from "@mc/constants";

import { useEngagementActivityFeedChangesCount } from "../api";
import { ENGAGEMENT_STATUS } from "../constants";

import { EngagementSettingsModal } from "./EngagementSettingsModal";
import { EngagementsPageTitleActions } from "./EngagementsPageTitleActions";

type Props = {
  engagementDetails: Engagement;
  loading: boolean;
  shouldShowAllItems: boolean;
  setShouldShowAllItems: (boolean) => void;
  engagementSettingsModalState: {
    isOpen: boolean;
    open: (...args: any[]) => void;
    close: (...args: any[]) => void;
  };
};

export const EngagementDetailsPageHeader = ({
  engagementDetails,
  loading,
  shouldShowAllItems,
  setShouldShowAllItems,
  engagementSettingsModalState,
}: Props) => {
  const isEngagementManager = useAccessRequired({
    role: ROLES.ENGAGEMENT_MANAGER,
  });
  const navigate = useNavigate();
  const {
    title,
    visible,
    source,
    id: engagementId,
    engagementType,
  } = engagementDetails;

  const handleTitleClick = () =>
    engagementType !== EngagementType.Elevate &&
    isEngagementManager &&
    engagementSettingsModalState.open();

  const handleStatusUpdateClick = () => {
    navigate(generatePath(PATHS._ENGAGEMENT_STATUS_EDIT, { engagementId }));
  };

  const handleShowAllItems = () => {
    setShouldShowAllItems((prev) => !prev);
  };

  const invisibleStatuses = [
    ENGAGEMENT_STATUS.IN_PROGRESS,
    ENGAGEMENT_STATUS.OKAY_TO_START,
  ];

  const isElevate = engagementDetails.engagementType === EngagementType.Elevate;

  return (
    <>
      <PageTitle
        data-testid="engagement-details-page-header"
        breadcrumbs={[
          { path: PATHS.ENGAGEMENTS_PROJECTS, name: "Projects" },
          {
            path: null,
            name: (
              <span
                data-testid="engagements-header-title"
                className={clsx("inline-flex items-center gap-1 text-default", {
                  "!text-accent": !title,
                })}
                onClick={handleTitleClick}
              >
                <Suspensed loading={loading} width="300px" height="1em">
                  <Tooltip
                    disabled={
                      !isEngagementManager ||
                      engagementType === EngagementType.Elevate
                    }
                    content={"Change Title"}
                  >
                    <span
                      className={clsx({
                        "cursor-pointer hover:text-accent":
                          isEngagementManager &&
                          engagementType !== EngagementType.Elevate,
                        "hover:text-default": !title,
                      })}
                    >
                      {title || "Untitled"}
                    </span>
                  </Tooltip>
                </Suspensed>
                {source?.status &&
                  invisibleStatuses.indexOf(source?.status) === -1 && (
                    <Badge label={status} size="small" />
                  )}
                {!visible && <Badge label="Private" size="small" />}
              </span>
            ),
          },
        ]}
        padding="small"
        actions={
          <div className="flex items-center gap-3">
            <RecentActivitiesButton loading={loading} />
            {isEngagementManager && (
              <EngagementsPageTitleActions
                loading={loading}
                shouldShowAllItems={shouldShowAllItems}
                onShowAllItems={handleShowAllItems}
                onStatusUpdateClick={!isElevate && handleStatusUpdateClick}
                onEditClick={engagementSettingsModalState.open}
              />
            )}
          </div>
        }
      />
      <EngagementSettingsModal
        engagement={engagementDetails}
        isModalOpen={engagementSettingsModalState.isOpen}
        onModalClose={engagementSettingsModalState.close}
      />
    </>
  );
};

const RecentActivitiesButton = ({ loading }) => {
  const navigate = useNavigate();
  const { engagementId } = useParams<{ engagementId: string }>();
  const {
    data: { activityCount },
    loading: activityCountLoading,
  } = useEngagementActivityFeedChangesCount();

  const handleClick = () => {
    const path = generatePath(PATHS._ENGAGEMENT_ACTIVITY_FEED, {
      engagementId,
    });
    navigate(path);
  };

  if (activityCountLoading) return null;

  return (
    <Suspensed loading={loading} width="154px" height="1em">
      <Link onClick={handleClick} className="text-xs font-light">
        <Badge
          label={activityCount}
          status="active"
          strong={true}
          className="mr-1"
          size="small"
        />
        View Recent Activities
      </Link>
    </Suspensed>
  );
};
