import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { useModalState } from "@m/ui";

import { HTMLContent } from "@mc/components/HTMLContent";
import { Page } from "@mc/components/Page";
import { PageTitle } from "@mc/components/PageTitle";
import { PATHS } from "@mc/constants";

import { useEngagementDetails } from "../api";
import {
  EngagementDetailsLists,
  EngagementDetailsPageHeader,
  EngagementSettingsModal,
  EngagementsPageTitleActions,
  EngagementsPoolHoursWidget,
} from "../components";

export const EngagementsElevateProjectPage = () => {
  const { engagementId } = useParams<{
    engagementId: string;
  }>();
  const [shouldShowAllItems, setShouldShowAllItems] = useState(false);
  const engagementSettingsModalState = useModalState();
  const location = useLocation();

  const {
    data: { engagementDetails },
    loading: engagementDetailsLoading,
  } = useEngagementDetails(engagementId);

  const handleShowAllItems = () => setShouldShowAllItems((prev) => !prev);

  const isLoading = engagementDetailsLoading;
  const isMarketplacePath = location.pathname.includes(
    PATHS.CLOUD_ENGAGEMENTS_PROJECTS_DETAILS
  );

  return (
    <>
      <Page
        data-testid="cloud-engagements-dashboard"
        width="large"
        title={
          isMarketplacePath ? (
            <>
              <PageTitle
                padding="small"
                breadcrumbs={[
                  {
                    path: PATHS.ENGAGEMENTS_PROJECTS,
                    name: "Projects",
                  },
                  {
                    path: PATHS.ENGAGEMENTS_PROJECTS_DETAILS,
                    name: "Dashboard",
                  },
                ]}
                actions={
                  <EngagementsPageTitleActions
                    loading={isLoading}
                    shouldShowAllItems={shouldShowAllItems}
                    onShowAllItems={handleShowAllItems}
                    onEditClick={engagementSettingsModalState.open}
                  />
                }
              />
              {!isLoading && (
                <EngagementSettingsModal
                  engagement={engagementDetails}
                  isModalOpen={engagementSettingsModalState.isOpen}
                  onModalClose={engagementSettingsModalState.close}
                />
              )}
            </>
          ) : (
            <EngagementDetailsPageHeader
              engagementDetails={engagementDetails || {}}
              loading={isLoading}
              shouldShowAllItems={shouldShowAllItems}
              setShouldShowAllItems={setShouldShowAllItems}
              engagementSettingsModalState={engagementSettingsModalState}
            />
          )
        }
      >
        <div className="flex flex-col gap-5">
          <div data-testid="engagement-details-header">
            <HTMLContent className="text-2xl font-semibold">
              {engagementDetails?.title || ""}
            </HTMLContent>
          </div>

          <EngagementsPoolHoursWidget
            poolHours={engagementDetails?.poolHours}
            onEditClick={engagementSettingsModalState.open}
            isLoading={isLoading}
          />

          <EngagementDetailsLists
            engagementId={engagementId}
            shouldShowAllItems={shouldShowAllItems}
          />
        </div>
      </Page>
    </>
  );
};
