import {
  CLOUD_FORMATION_REGION,
  CLOUD_FORMATION_TEMPLATE_URL_BASE,
  CLOUD_FORMATION_TEMPLATE_URL_CONFIGS,
  CLOUD_FORMATION_URL_BASE,
  CloudFormationTemplateUrlConfig,
  SKUS,
} from "@mc/constants";

interface TemplateUrlOptions {
  iamExternalId?: string;
  isLinkedAccount?: boolean;
  isPayerAccount?: boolean;
}

export const getCloudFormationTemplateURL = (
  sku?: string,
  options?: TemplateUrlOptions
): string => {
  const { stackName, templateFileName } = getTemplateUrlConfig(sku, options);

  const url = new URL(CLOUD_FORMATION_URL_BASE);
  const params = new URLSearchParams({ stackName });

  if (options?.iamExternalId)
    params.append("param_ExternalId", options.iamExternalId);

  params.append(
    "templateURL",
    `${CLOUD_FORMATION_TEMPLATE_URL_BASE}/${templateFileName}`
  );

  url.searchParams.set("region", CLOUD_FORMATION_REGION);
  url.hash = `/stacks/create/review?${params.toString()}`;
  return url.toString();
};

const getTemplateUrlConfig = (
  sku?: string,
  options?: TemplateUrlOptions
): CloudFormationTemplateUrlConfig => {
  const {
    CLOUD_GATEWAY_CHILD,
    CLOUD_GATEWAY_PAYER,
    CLOUD_SCORE,
    CLOUD_SECURE_CHILD,
    CLOUD_SECURE_PAYER,
    IDENTIFY_PAYER,
    ONBOARDING,
  } = CLOUD_FORMATION_TEMPLATE_URL_CONFIGS;

  switch (sku) {
    case SKUS.CLOUD_SCORE:
      return CLOUD_SCORE;

    case SKUS.CLOUD_GATEWAY:
      if (options?.isPayerAccount) return CLOUD_GATEWAY_PAYER;
      if (options?.isLinkedAccount) return CLOUD_GATEWAY_CHILD;
      return IDENTIFY_PAYER;

    case SKUS.CLOUD_SECURE:
      if (options?.isPayerAccount) return CLOUD_SECURE_PAYER;
      if (options?.isLinkedAccount) return CLOUD_SECURE_CHILD;
      return IDENTIFY_PAYER;

    default:
      return ONBOARDING;
  }
};
