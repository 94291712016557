import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { toast } from "@m/ui";

export const METERED_CHARGES_TRANSACTIONS_FAILURE_MESSAGE =
  "Unable to get subscription transactions";

export const GET_METERED_CHARGES_TRANSACTIONS = gql(/* GraphQL */ `
  query GetMarketplaceMeteredCharges(
    $where: MarketplaceMeteringTransactionFilter
  ) {
    marketplaceMeteringTransactions(where: $where) {
      edges {
        node {
          id
          company {
            id
          }
          billingPeriod {
            isoformat
          }
          dimension {
            id
            atlasName
            displayName
          }
          quantity
          sentTimestamp
          marketplaceProductCode
          successfullySent
          catalogItem {
            id
            displayName
          }
          reportedDate
          successfullySentTimestamp
          account {
            id
            name
          }
        }
      }
    }
  }
`);

export const useMeteredCharges = () => {
  const onError = () =>
    toast.error(METERED_CHARGES_TRANSACTIONS_FAILURE_MESSAGE);

  const { data, ...result } = useQuery(GET_METERED_CHARGES_TRANSACTIONS, {
    onError,
  }); // TODO(jamesmoody): add support for filtering

  const edges = data?.marketplaceMeteringTransactions?.edges ?? [];
  const meteredCharges =
    edges
      .map((edge) => {
        return edge?.node;
      })
      .filter((charge) => charge !== null) ?? [];

  return {
    data: { meteredCharges },
    ...result,
  };
};
