import {
  EngagementActivityFeedItemActionItem,
  EngagementActivityFeedItemBudgetSpent,
  EngagementActivityFeedItemBudgetStatusChange,
  EngagementActivityFeedItemDecision,
  EngagementActivityFeedItemProjectStart,
} from "../components";

import {
  ENGAGEMENT_ACTIVITY_FEED_GROUPS,
  type EngagementActivityFeedComponent,
  type EngagementActivityFeedGroupConfig,
} from "./activityFeed";

interface EngagementActivityFeedMap {
  [__typename: string]: {
    __typename: string;
    group: EngagementActivityFeedGroupConfig;
    type: string;
    component: EngagementActivityFeedComponent;
  };
}

export const ENGAGEMENT_ACTIVITY_FEED_MAP: EngagementActivityFeedMap = {
  EngagementStartActivityStream: {
    __typename: "EngagementStartActivityStream",
    group: ENGAGEMENT_ACTIVITY_FEED_GROUPS.projectUpdates.id,
    type: "engagement.started",
    component: EngagementActivityFeedItemProjectStart,
  },
  EngagementBudgetSpentActivityStream: {
    __typename: "EngagementBudgetSpentActivityStream",
    group: ENGAGEMENT_ACTIVITY_FEED_GROUPS.budget.id,
    type: "engagement.budget_spent",
    component: EngagementActivityFeedItemBudgetSpent,
  },
  EngagementBudgetStatusChangeActivityStream: {
    __typename: "EngagementBudgetStatusChangeActivityStream",
    group: ENGAGEMENT_ACTIVITY_FEED_GROUPS.budget.id,
    type: "engagement.budget_status_change",
    component: EngagementActivityFeedItemBudgetStatusChange,
  },
  EngagementDecisionActivityStream: {
    __typename: "EngagementDecisionActivityStream",
    group: ENGAGEMENT_ACTIVITY_FEED_GROUPS.decision.id,
    type: "engagement.decision",
    component: EngagementActivityFeedItemDecision,
  },
  EngagementActionItemCreatedActivityStream: {
    __typename: "EngagementActionItemCreatedActivityStream",
    group: ENGAGEMENT_ACTIVITY_FEED_GROUPS.actionItem.id,
    type: "engagement.action_item_created",
    component: EngagementActivityFeedItemActionItem,
  },
  EngagementActionItemCompletedActivityStream: {
    __typename: "EngagementActionItemCompletedActivityStream",
    group: ENGAGEMENT_ACTIVITY_FEED_GROUPS.actionItem.id,
    type: "engagement.action_item_completed",
    component: EngagementActivityFeedItemActionItem,
  },
  EngagementActionItemNoteAddedActivityStream: {
    __typename: "EngagementActionItemNoteAddedActivityStream",
    group: ENGAGEMENT_ACTIVITY_FEED_GROUPS.actionItem.id,
    type: "engagement.action_item_note_added",
    component: EngagementActivityFeedItemActionItem,
  },
} as const;
