import { Outlet, RouteObject } from "react-router-dom";

import { AccessRequired } from "@mc/components/AccessRequired";
import { MUTATIONS, PATHS } from "@mc/constants";
import {
  CaseDetailsPage,
  CaseListPage,
  CreateCasePage,
} from "@mc/features/CustomerCases";
import { AccessDenied } from "@mc/pages";
import { Navigate } from "@mc/router";

export const supportRoutes: RouteObject[] = [
  {
    path: "cases",
    children: [
      {
        index: true,
        path: "",
        element: <CaseListPage />,
      },
      {
        index: true,
        path: ":sysId",
        element: <CaseDetailsPage />,
      },
      {
        path: "create",
        element: (
          <AccessRequired
            mutation={MUTATIONS.CREATE_CASE}
            fallback={<AccessDenied />}
          >
            <Outlet />
          </AccessRequired>
        ),
        children: [
          {
            index: true,
            path: "",
            element: <CreateCasePage />,
          },
          {
            index: true,
            path: ":caseType",
            element: <CreateCasePage />,
            errorElement: <Navigate replace to={PATHS.SUPPORT_CASES_CREATE} />,
          },
        ],
      },
    ],
  },
  {
    index: true,
    path: "*",
    element: <Navigate replace to={PATHS.SUPPORT_CASES} />,
  },
];
