interface ProductOffering {
  sku: string;
  requiresAccountOnboarding: boolean;
  requiresPayerAccount: boolean;
}

interface ProductCatalog {
  [PRODUCT_KEY: string]: ProductOffering;
}

const productCatalog: ProductCatalog = {
  CLOUD_SCORE: {
    sku: "MC-SAAS-CLOUDSCORE-2023-11",
    requiresAccountOnboarding: true,
    requiresPayerAccount: false,
  },
  CLOUD_GATEWAY: {
    sku: "MC-SAAS-CLOUDGATEWAY-2024-07",
    requiresAccountOnboarding: true,
    requiresPayerAccount: true,
  },
  CLOUD_ENGAGEMENTS: {
    sku: "MC-SAAS-ENGAGEMENTS-DEVOPS-2024-07",
    requiresAccountOnboarding: false,
    requiresPayerAccount: false,
  },
  CLOUD_SECURE: {
    sku: "MC-SAAS-CLOUD-SECURE-2024-10",
    requiresAccountOnboarding: true,
    requiresPayerAccount: true,
  },
};

export const SKUS = Object.fromEntries(
  Object.entries(productCatalog).map(([productKey, productOffering]) => [
    productKey,
    productOffering.sku,
  ])
);

export const SUPPORTED_PRODUCT_SKUS = Object.values(SKUS);

export const AWS_ACCOUNT_REQUIRED_SKUS = Object.values(productCatalog)
  .filter(
    (product) =>
      product.requiresAccountOnboarding && !product.requiresPayerAccount
  )
  .map((product) => product.sku);

export const PAYER_ACCOUNT_REQUIRED_SKUS = Object.values(productCatalog)
  .filter(
    (product) =>
      product.requiresAccountOnboarding && product.requiresPayerAccount
  )
  .map((product) => product.sku);

export const NO_ACCOUNT_REQUIRED_SKUS = Object.values(productCatalog)
  .filter(
    (product) =>
      !product.requiresAccountOnboarding && !product.requiresPayerAccount
  )
  .map((product) => product.sku);
