import { gql } from "@m/api/public";
import { toast } from "@m/ui";

import { ACTIVITY_FEED_MESSAGES } from "@mc/features/ActivityFeed/constants";
import { useQuery } from "@mc/utils/graphql";

import { ENGAGEMENT_ACTIVITY_FEED_MAP } from "../constants";
import { EngagementActivityStreamNode } from "../types";

export const GET_ENGAGEMENTS_ACTIVITY_STREAM = gql(`
query EngagementsActivityStream($activityStreamFilter: ActivityStreamFilter, $first: Int, $after: String) {
  activityStream(where: $activityStreamFilter, first: $first, after: $after) {
    edges {
      node {
        __typename
        ... on EngagementStartActivityStream {
          id
          timestamp
          operation
          type
          changes
          engagement {
            id
            title
          }
        }
        ... on EngagementBudgetStatusChangeActivityStream {
          id
          timestamp
          operation
          type
          changes
          budgetStatus {
            id
          }
          engagement {
            id
          }
        }
        ... on EngagementBudgetSpentActivityStream {
          id
          timestamp
          operation
          type
          changes
          before
          after
          budgetStatus {
            id
          }
          engagement {
            id
          }
        }
        ... on EngagementDecisionActivityStream {
          id
          timestamp
          operation
          type
          changes
          decision {
            id
            title
          }
          engagement {
            id
          }
        }
        ... on EngagementActionItemCreatedActivityStream {
          id
          timestamp
          operation
          type
          changes
          actionItem {
            id
            title
          }
          engagement {
            id
          }
        }
        ... on EngagementActionItemCompletedActivityStream {
          id
          timestamp
          operation
          type
          changes
          actionItem {
            id
            title
          }
          engagement {
            id
          }
        }
        ... on EngagementActionItemNoteAddedActivityStream {
          id
          timestamp
          operation
          type
          changes
          actionItem {
            id
            title
          }
          engagement {
            id
          }
        }
      }
    }
  }
}
`);

export const useEngagementsActivityStream = ({
  cutoff,
}: {
  cutoff: string;
}) => {
  const onError = () =>
    toast.error(ACTIVITY_FEED_MESSAGES.GET_ACTIVITY_STREAM_FAILURE);

  const streamTypes = [
    ENGAGEMENT_ACTIVITY_FEED_MAP.EngagementStartActivityStream,
    ENGAGEMENT_ACTIVITY_FEED_MAP.EngagementBudgetSpentActivityStream,
    ENGAGEMENT_ACTIVITY_FEED_MAP.EngagementBudgetStatusChangeActivityStream,
    ENGAGEMENT_ACTIVITY_FEED_MAP.EngagementDecisionActivityStream,
    ENGAGEMENT_ACTIVITY_FEED_MAP.EngagementActionItemCreatedActivityStream,
    ENGAGEMENT_ACTIVITY_FEED_MAP.EngagementActionItemCompletedActivityStream,
    ENGAGEMENT_ACTIVITY_FEED_MAP.EngagementActionItemNoteAddedActivityStream,
  ].map((stream) => stream.type);

  const { data, loading, error } = useQuery(GET_ENGAGEMENTS_ACTIVITY_STREAM, {
    variables: {
      activityStreamFilter: {
        timestampGte: cutoff,
        typeIn: streamTypes,
      },
    },
    onError,
  });

  const activities =
    data?.activityStream?.edges?.map((edge) => edge.node) || [];

  return {
    data: { activities: activities as EngagementActivityStreamNode[] },
    loading,
    error: error !== undefined,
  };
};
