import { ArrowUpRightIcon } from "@heroicons/react/24/outline";

import { Banner, Link } from "@m/ui";

import { CLOUD_FORMATION_IAM_ROLES, SKUS } from "@mc/constants";
import { getCloudFormationTemplateURL } from "@mc/utils/cloudFormation";

import { useMissingIamRoles } from "../api";

export const MissingIamRoleBanner = () => {
  const {
    data: { awsAccountId, missingIamRoles, iamExternalId },
  } = useMissingIamRoles();

  // currently only the cloud gateway billing role is supported
  const billingRole = CLOUD_FORMATION_IAM_ROLES.MISSION_CONTROL_GATEWAY_BILLING;

  let cloudFormationTemplateUrl: string;
  if (missingIamRoles.includes(billingRole)) {
    cloudFormationTemplateUrl = getCloudFormationTemplateURL(
      SKUS.CLOUD_GATEWAY,
      { isPayerAccount: true, iamExternalId }
    );
  }

  if (!cloudFormationTemplateUrl) return null;

  return (
    <Banner
      data-testid="missing-iam-role-banner"
      className="mb-4"
      status="warning"
      title="Missing Required IAM Roles"
      label={
        <div className="w-full">
          Your AWS account <span className="font-semibold">{awsAccountId}</span>{" "}
          is missing the{" "}
          <span className="font-semibold">
            {formattedRoleList(missingIamRoles)}
          </span>{" "}
          IAM role{missingIamRoles.length > 1 ? "s" : ""}. <br />
          <span className="font-normal italic text-yellow-700">
            Note: Changes should take effect within one hour or less.
          </span>
        </div>
      }
      actions={
        <Link
          className="w-fit whitespace-nowrap text-end text-yellow-600"
          href={cloudFormationTemplateUrl}
          target="_blank"
          rightIcon={ArrowUpRightIcon}
        >
          Run CloudFormation Template
        </Link>
      }
    />
  );
};

function formattedRoleList(missingIamRoles: string[]) {
  if (missingIamRoles.length === 0) {
    return "";
  } else if (missingIamRoles.length === 1) {
    return missingIamRoles[0];
  } else if (missingIamRoles.length >= 2) {
    return missingIamRoles.join(" and ");
  } else {
    const lastElement = missingIamRoles.pop();
    return `${missingIamRoles.join(", ")}, and ${lastElement}`;
  }
}
