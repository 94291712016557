import { useMemo } from "react";

import { Table } from "@m/ui";
import { formatFullDate } from "@m/utils";

import { PageHeading } from "@mc/components/PageHeading";

import { useMeteredCharges } from "../api";
import { MeteredChargeDateTooltip } from "../components";

export const MeteredChargesTable = () => {
  const {
    data: { meteredCharges },
    loading,
  } = useMeteredCharges();

  const rows = useMemo(() => {
    return meteredCharges.map((meteredCharge) => {
      return {
        productName: meteredCharge?.catalogItem?.displayName || "—",
        billingPeriod:
          formatFullDate(
            meteredCharge?.billingPeriod?.isoformat,
            "MMMM yyyy"
          ) || "—",
        dimension: meteredCharge?.dimension?.displayName || "—",
        meteredUnits: meteredCharge?.quantity,
        reportedDate: (
          <MeteredChargeDateTooltip
            isPending={false}
            timestamp={meteredCharge?.reportedDate}
          />
        ),
        chargeDate: (
          <MeteredChargeDateTooltip
            isPending={!meteredCharge?.successfullySent}
            timestamp={meteredCharge?.successfullySentTimestamp}
          />
        ),
      };
    });
  }, [meteredCharges]);

  return (
    <div data-testid="metered-charges-table" className="mt-4 flex flex-col">
      <PageHeading heading="Transactions" />
      <Table
        headerFontSize="small"
        headers={TABLE_HEADERS}
        pageSize={30}
        loading={loading}
        rows={rows}
        emptyMessage="No transactions found"
      />
    </div>
  );
};

const TABLE_HEADERS = [
  { label: "Product Name", accessor: "productName" },
  { label: "Billing Period", accessor: "billingPeriod" },
  { label: "Dimension", accessor: "dimension" },
  {
    label: "Metered Units",
    accessor: "meteredUnits",
  },
  { label: "Reported Date", accessor: "reportedDate" },
  { label: "Charge Date", accessor: "chargeDate" },
];
