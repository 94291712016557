import { useMemo } from "react";

import { Badge } from "@m/ui";
import { toHyphenLowercase } from "@m/utils";

import {
  ENGAGEMENT_ACTIVITY_FEED_MAP,
  type EngagementActivityFeedGroupConfig,
} from "../constants";
import { EngagementActivityStreamNode } from "../types";
import {
  filterActivitiesByGroup,
  sortByTimestamp,
} from "../utils/activityFeed";

interface Props {
  title: string;
  group: EngagementActivityFeedGroupConfig;
  activities?: EngagementActivityStreamNode[];
}

export const EngagementActivityFeedGroup = ({
  activities,
  title,
  group,
}: Props) => {
  const activityFeedItems = useMemo<Array<JSX.Element>>(() => {
    const filteredActivities = filterActivitiesByGroup(activities, group);
    const orderedByTimestamp = sortByTimestamp(filteredActivities);

    return orderedByTimestamp.map((item: EngagementActivityStreamNode) => {
      const ActivityStreamItem =
        ENGAGEMENT_ACTIVITY_FEED_MAP[item.__typename]?.component;

      let before;
      let after;
      let model;
      switch (item.__typename) {
        case "EngagementDecisionActivityStream":
          model = item.decision;
          break;
        case "EngagementActionItemCreatedActivityStream":
        case "EngagementActionItemCompletedActivityStream":
        case "EngagementActionItemNoteAddedActivityStream":
          model = item.actionItem;
          break;
        case "EngagementStartActivityStream":
          model = item.engagement;
          break;
        case "EngagementBudgetSpentActivityStream":
        case "EngagementBudgetStatusChangeActivityStream":
          break;
        default:
          break;
      }

      return (
        <ActivityStreamItem
          id={item.id}
          model={model}
          before={before}
          after={after}
          operation={item.operation}
          changes={item.changes}
          timestamp={item.timestamp}
          key={item.id}
        />
      );
    });
  }, [activities, group]);

  const id = `activities-from-${toHyphenLowercase(title)}`;
  const groupHasActivityItems = activityFeedItems.length > 0;

  return groupHasActivityItems ? (
    <div
      className="mt-4 flex flex-col space-y-1"
      data-testid={`${title.toLowerCase().replace(" ", "-")}-activity-group`}
    >
      <div className="flex items-center space-x-2">
        <Badge
          data-testid={`${title
            .toLowerCase()
            .replace(" ", "-")}-activity-badge`}
          label={title}
          status="default"
          className="font-semibold text-gray-600"
        />
      </div>
      <div aria-labelledby={id} className="flex flex-col space-y-1">
        {activityFeedItems}
      </div>
    </div>
  ) : null;
};
